<template>
    <div class="building_wrap">
        <DeleteSpace
            :spaceNm="spaceNm"
            :spaceId="spaceId"
            v-if="ShowDelete"
            @closeDelete="ShowDelete = false"
            @getList="getList()"
        />
        <Resubscribe
            :spaceId="spaceId"
            :spaceType="spaceType"
            :mode="mode"
            v-if="ShowResubscribe"
            @closeResubscribe="ShowResubscribe = false"
            @getList="getList()"
        />
        <AddSpace :spaceId="spaceId" :spaceType="spaceType" v-if="ShowAddSpace" @closeAddSpace="ShowAddSpace = false" />
        <Disconnect
            :brandNm="brandNm"
            :brandId="brandId"
            :spaceId="spaceId"
            v-if="isDisconnect"
            @closeDisconnect="isDisconnect = false"
            @getList="getList()"
        />
        <div class="space_pc building_space">
            <section class="building_space_main">
                <div class="main_box">
                    <div class="building_space_main_box_title_wrap">
                        <h1 class="building_space_main_box_title">마이 스페이스</h1>
                        <button type="button" class="add_new_space" @click="linkInsertSpace()" v-if="spaceList.length > 0">
                            <img class="add_new_space_plusimg" src="/media/img/buildingTypeSpace/icon/add_new_space.svg" alt="" />
                            신규 스페이스 추가
                        </button>
                    </div>
                    <!-- 생성된 스페이스가 없을 경우 -->
                    <div class="w_100" v-if="spaceList.length === 0">
                        <div class="building_empty_card" @click="linkInsertSpace()">
                            <img src="/media/img/card_upload.png" alt="" />
                            <p class="building_empty_card_text">신규 스페이스 추가</p>
                        </div>
                    </div>
                    <!-- 생성된 스페이스 목록 -->
                    <div class="building_space_content" v-else>
                        <div v-for="(item, index) in spaceList" :key="item.spaceId">
                            <!-- 스페이스 카드 -->
                            <building-spacecard
                                :index="index"
                                :spaceid="item.spaceId"
                                :spacenm="item.spaceNm ? item.spaceNm : ''"
                                :acceptat="item.acceptAt ? item.acceptAt : 'N'"
                                :spacepayat="item.spacePayAt ? item.spacePayAt : 'N'"
                                :useipspeaker="item.useIpSpeaker ? item.useIpSpeaker : 'N'"
                                :isplayyn="item.isPlayYn ? item.isPlayYn : 'N'"
                                :baseimgurl="baseImgUrl"
                                :albumimg="item.albumImg ? item.albumImg : null"
                                :musicnm="item.musicNm ? item.musicNm : null"
                                :artist="item.artist ? item.artist : null"
                                :ismuteyn="item.isMuteYn ? item.isMuteYn : 'N'"
                                :playvolume="item.playVolume ? item.playVolume : '0'"
                                :preplayvolume="item.prePlayVolume ? item.prePlayVolume : '0'"
                                :requestat="item.requestAt ? item.requestAt : 'N'"
                                :autoplay="item.autoplay ? item.autoplay : 'N'"
                                :mood="item.mood ? item.mood : ''"
                                :target="item.target ? item.target : []"
                                :targetunit="item.targetUnit ? item.targetUnit : []"
                                :domesticrate="item.domesticRate ? item.domesticRate : '0'"
                                :genre="item.genre ? item.genre : []"
                                :period="item.period ? item.period : []"
                                :errorstatus="item.err"
                                :brandid="item.brandId ? item.brandId : ''"
                                :brandnm="item.brandNm ? item.brandNm : ''"
                                :spacetype="item.spaceType ? item.spaceType : 'Building'"
                                :socket="item.socket ? item.socket : 'N'"
                                :isloading="item.isLoading ? item.isLoading : false"
                                :spaceip="item.spaceIp ? item.spaceIp : ''"
                                @move-pagenoparam="onMovePageNoParam"
                                @disconnect-brand="onDisconnectBrand"
                                @update-franchise="onUpdateFranchise"
                                @pause-music="onPauseMusic"
                                @play-music="onPlayMusic"
                                @unmute-sound="onUnMuteSound"
                                @mute-sound="onMuteSound"
                                @setting-volume="onSettingVolume"
                                @delete-space="onDeleteSpace"
                            />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import AddSpace from '@/components/modal/Space/AddSpace.vue';
import Resubscribe from '@/components/modal/Space/Resubscribe.vue';
import DeleteSpace from '@/components/modal/Space/DeleteSpace.vue';
import Disconnect from '@/components/modal/Franchise/Disconnect.vue';
import BuildingSpaceCard from '@/components/buildingspace/BuildingSpaceCard.vue';
import axios from '@/service/axios';
import router from '@/router';
import Hls from 'hls.js';
import { getMusicList, getBldgState, setBldgStop, setBldgPlay, setBldgVolume } from '@/service/api/playerApi';
import { getMySpaceList } from '@/service/api/profileApi';
import { cloneDeep } from 'lodash-es';
export default {
  name: 'MySpaceBuilding',
  components: {
    AddSpace,
    Resubscribe,
    DeleteSpace,
    Disconnect,
    'building-spacecard': BuildingSpaceCard
  },
  data () {
    return {
      config: {
        debug: window.location.host === 'dev.www.aplayz.co.kr',
        startPosition: -1,
        enableWorker: true,
        lowLatencyMode: true,
        maxBufferLength: 30,
        maxMaxBufferLength: 600,
        backBufferLength: Infinity,
        manifestLoadingTimeOut: 10000,
        manifestLoadingMaxRetry: 1,
        manifestLoadingRetryDelay: 1000,
        manifestLoadingMaxRetryTimeout: 64000,
        startLevel: undefined,
        levelLoadingTimeOut: 10000,
        levelLoadingMaxRetry: 10,
        levelLoadingRetryDelay: 1000,
        levelLoadingMaxRetryTimeout: 64000,
        fragLoadingTimeOut: 30000,
        fragLoadingMaxRetry: 10,
        fragLoadingRetryDelay: 1000,
        fragLoadingMaxRetryTimeout: 100000
      },
      errorlog: [],
      array: [],
      buildingSoundArray: [],
      ShowAddSpace: false,
      ShowResubscribe: false,
      ShowDelete: false,
      isDisconnect: false,
      baseImgUrl: '/media/album/aplayz.cover/',
      spaceList: [],
      spacePlayList: [],
      playingMusicInfoList: [],
      limit: 999,
      infiniteId: new Date(),
      brandId: '',
      brandNm: '',
      spaceId: '',
      mode: '',
      autoPlayInfoList: [],
      counter: 0,
      buildingSoundList: [],
      buildingSoundInfo: [],
      hyundaiCapatialList: [],
      isHyundaiCapatial: false,
      email: this.$cookies.get('email'),
      headers: {
        'Content-Type': 'text/plain;charset=UTF-8',
        Accept: '*/*'
      },
      buildingWorker: null,
      dot42List: [],
      instance: axios.create(),
      userId: this.$cookies.get('userId')
    };
  },
  async created () {
    this.modeCheck();
    if (!this.$store.getters['spacebuilding/getBuildingWorker']) {
      this.$store.dispatch('spacebuilding/addWorker');
    }
    this.buildingWorker = this.$store.getters['spacebuilding/getBuildingWorker'];
    this.buildingWorkerAddEventListener();
    this.buildingWorker.postMessage({
      message: 'CHECK_STATUS'
    });

    this.$AutoPlay.init();
    await this.setSpaceList();
    this.playNotIpModule();
    this.setHyundaiCapitalList();
    this.set42dotList();
  },
  mounted () {
    this.getBuildingSoundList();
  },
  beforeDestroy () {
    this.buildingWorker.postMessage({
      message: 'CLEAR_INTERVAL'
    });
    this.buildingWorker.removeEventListener('message', this.musicInfoList);
  },
  destroyed () {
    this.$AutoPlay.destoryInterval();
  },
  methods: {
    musicInfoList () {
      this.setMusicInfoList();
    },
    buildingWorkerAddEventListener () {
      this.buildingWorker.addEventListener('message', this.musicInfoList);
    },
    playNotIpModule () {
      const findIdx = this.spaceList.findIndex(item => item.useIpSpeaker === 'N');
      if (findIdx > -1) {
        const findNotIpSpeakier = this.spaceList[findIdx];
        const { isPlayYn } = findNotIpSpeakier;
        if (isPlayYn === 'Y' && this.$store.state.isPlay === '') {
          this.$store.commit('setPlay', true);
          const space = cloneDeep(this.spaceList[findIdx]);
          space.spaceIndex = findIdx;
          this.onPlayMusic(space);
        } else if (isPlayYn === 'Y') {
          this.$store.commit('setPlay', true);
        } else {
          this.$store.commit('setPlay', false);
        }
      }
    },
    setHyundaiCapitalList () {
      const host = window.location.host;
      if (host === 'www.aplayz.co.kr') {
        this.hyundaiCapatialList.push('space_013977');
      } else {
        this.hyundaiCapatialList.push('space_002387');
      }
    },
    set42dotList () {
      const host = window.location.host;
      if (host === 'www.aplayz.co.kr') {
        this.dot42List.push('space_014767');
      } else {
        this.dot42List.push('space_002544');
      }
    },
    /**
     * @description 현대캐피탈 자동재생 하드코딩
     */
    setHyundaiCapitalAutoPlay (spaceId, week) {
      // 현대캐피탈 자동재생 시작1
      const isHyundaiCapatial = this.hyundaiCapatialList.includes(spaceId);

      if (isHyundaiCapatial) {
        this.$crontab.addJob({
          name: 'HyundaiCapitalAutoPlayStart1' + week,
          interval: { seconds: '00', minutes: '00', hours: '09', week: week.toString() },
          job: () => {
            const find = this.spaceList.find(spaceItem => spaceItem.spaceId === spaceId);
            const spaceIndex = this.spaceList.findIndex(spaceItem => spaceItem.spaceId === spaceId);
            const isPlay = find.isPlayYn === 'Y';
            if (!isPlay) {
              const space = cloneDeep(this.spaceList[spaceIndex]);
              space.spaceIndex = spaceIndex;
              this.onPlayMusic(space);
            }
          }
        });
        // 현대캐피탈 자동재생 종료1
        this.$crontab.addJob({
          name: 'HyundaiCapitalAutoPlayEnd1' + week,
          interval: { seconds: '00', minutes: '30', hours: '08', week: week.toString() },
          job: () => {
            const find = this.spaceList.find(spaceItem => spaceItem.spaceId === spaceId);
            const spaceIndex = this.spaceList.findIndex(spaceItem => spaceItem.spaceId === spaceId);
            const isPlay = find.isPlayYn === 'Y';

            if (isPlay) {
              const space = cloneDeep(this.spaceList[spaceIndex]);
              space.spaceIndex = spaceIndex;
              this.onPauseMusic(space);
            }
          }
        });

        // 현대캐피탈 자동재생 시작2
        this.$crontab.addJob({
          name: 'HyundaiCapitalAutoPlayStart2' + week,
          interval: { seconds: '00', minutes: '30', hours: '12', week: week.toString() },
          job: () => {
            const find = this.spaceList.find(spaceItem => spaceItem.spaceId === spaceId);
            const isPlay = find.isPlayYn === 'Y';
            const spaceIndex = this.spaceList.findIndex(spaceItem => spaceItem.spaceId === spaceId);
            if (!isPlay) {
              const space = cloneDeep(this.spaceList[spaceIndex]);
              space.spaceIndex = spaceIndex;
              this.onPlayMusic(space);
            }
          }
        });
        // 현대캐피탈 자동재생 종료2
        this.$crontab.addJob({
          name: 'HyundaiCapitalAutoPlayEnd2' + week,
          interval: { seconds: '00', minutes: '00', hours: '12', week: week.toString() },
          job: () => {
            const find = this.spaceList.find(spaceItem => spaceItem.spaceId === spaceId);
            const isPlay = find.isPlayYn === 'Y';
            const spaceIndex = this.spaceList.findIndex(spaceItem => spaceItem.spaceId === spaceId);
            if (isPlay) {
              const space = cloneDeep(this.spaceList[spaceIndex]);
              space.spaceIndex = spaceIndex;
              this.onPauseMusic(space);
            }
          }
        });
      }
    },
    /**
     * @description 42dot 자동재생 하드코딩
     */
    set42dotAutoPlay (spaceId, week) {
      const is42dot = this.dot42List.includes(spaceId);
      if (is42dot) {
        // 42dot라운지화장실(3F) 자동재생
        this.$crontab.addJob({
          name: '42dotAutoStart' + week,
          interval: { seconds: '00', minutes: '30', hours: '11', week: week.toString() },
          job: () => {
            const find = this.spaceList.find(spaceItem => spaceItem.spaceId === spaceId);
            const isPlay = find.isPlayYn === 'Y';
            const spaceIndex = this.spaceList.findIndex(spaceItem => spaceItem.spaceId === spaceId);
            if (!isPlay) {
              const space = cloneDeep(this.spaceList[spaceIndex]);
              space.spaceIndex = spaceIndex;
              this.onPlayMusic(space);
            }
          }
        });

        // 42dot라운지화장실(3F) 자동중지
        this.$crontab.addJob({
          name: '42dotAutoStop' + week,
          interval: { seconds: '00', minutes: '00', hours: '11', week: week.toString() },
          job: () => {
            const find = this.spaceList.find(spaceItem => spaceItem.spaceId === spaceId);
            const isPlay = find.isPlayYn === 'Y';
            const spaceIndex = this.spaceList.findIndex(spaceItem => spaceItem.spaceId === spaceId);
            if (isPlay) {
              const space = cloneDeep(this.spaceList[spaceIndex]);
              space.spaceIndex = spaceIndex;
              this.onPauseMusic(space);
            }
          }
        });
      }
    },
    fadeOutSound () {
      return new Promise((resolve, reject) => {
        const video = document.getElementById('video');
        const fadeoutInterval = setInterval(fadeout, 500);

        function fadeout () {
          if (video.volume > 0.1) {
            video.volume -= 0.1; // Decrease the volume by 10% every 500ms
          } else {
            video.volume = 0;
            clearInterval(fadeoutInterval);
            resolve();
          }
        }
      });
    },
    deleteBuildingSoundJob () {
      const array = Object.keys(this.$crontab.jobs);

      this.buildingSoundArray = array.filter(job => {
        return job.includes('brand');
      });

      for (var j in this.buildingSoundArray) {
        this.$crontab.deleteJob(this.buildingSoundArray[j]);
      }
    },
    async getBuildingSoundList () {
      const spaceId = this.$cookies.get('userId');
      const aToken = this.$cookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };
      // 건물형 방송/홍보 음원 리스트
      await axios
        .post('/api/player/selectBuildingSoundList', { spaceId }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            this.buildingSoundList = res.data.result;
            setTimeout(() => {
              // 건물형 방송/홍보 음원 cron 셋팅
              this.setBuildingSound();
            }, 1000);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    setBrandMusic () {
      const len = this.buildingSoundInfo.length;
      for (let index = 0; index < len; index++) {
        const { hours, minutes, musicId, name, spaceId } = this.buildingSoundInfo[index];

        this.$crontab.addJob({
          name: `brand_${name}_${spaceId}`,
          interval: {
            seconds: '0',
            minutes,
            hours
          },
          job: async () => {
            const spaceInfo = this.spaceList.find(item => item.spaceId === spaceId);
            const findIdx = this.spaceList.findIndex(item => item.spaceId === spaceId);
            const { useIpSpeaker, spaceIp, playVolume, isPlayYn, socket } = spaceInfo;
            // 브랜드 음원 세팅
            const musicList = await this.setSpaceMusicList(spaceId, '0', this.email, '2022-03-15');
            this.setUpdateSpaceList(spaceId, musicList);
            if (socket === 'N') {
              if (isPlayYn === 'Y') {
                if (useIpSpeaker === 'Y') {
                  // fadeOut
                  await this.ipSpeakerFadeOutVolume(spaceIp, this.headers, playVolume);

                  try {
                    // 중지
                    await this.ipSpeakerPauseMusic(spaceIp, 0, null, null, async () => {
                      // 중지 처리
                      await this.updateSpaceIsPlayYn(spaceId, 'N');
                      // 음악 추출
                      const addUrlMusicList = this.settingMusicList(musicList, useIpSpeaker, musicId);
                      try {
                        // IP모듈 플레이
                        const isSuccess = await this.playIpSpeaker(0, spaceInfo, spaceIp, addUrlMusicList);
                        if (isSuccess) {
                          // 음원 정보 갱신 처리
                          const { data } = await this.updatePlayingList(spaceId, musicList);
                          const { resultCd } = data;
                          if (resultCd === '0000') {
                            this.spaceList[findIdx].isPlayingMusicList = musicList;

                            const { data } = await this.ipSpeakerPlayMusic(spaceIp);

                            const { success } = data;
                            if (success) {
                              await this.ipSpeakerSetVolume(spaceIp, spaceInfo);
                              const { data: updateSpace } = await this.updateSpaceIsPlayYn(spaceId, 'Y');
                              const { resultCd: updateSpaceResultCd } = updateSpace;
                              if (updateSpaceResultCd === '0000') {
                                this.spaceList[findIdx].isPlayYn = 'Y';
                                // 음량 원복 시키기
                              } else {
                                this.spaceList[findIdx].isPlayYn = 'N';
                              }
                            }
                          }
                        }
                      } catch (error) {
                        this.spaceList[findIdx].isPlayYn = 'N';
                        console.error('error : ipSpeakerPauseMusic : ', error);
                      }
                    });
                  } catch (error) {
                    //
                  }
                } else {
                  const spaceIndex = this.spaceList.findIndex(item => item.spaceId === spaceId);
                  if (spaceIndex > -1) {
                    // fadeOut
                    await this.$VideoPlayer.fadeOutSound();

                    // 중지
                    await this.updateSpaceIsPlayYn(spaceId, 'N');

                    // 브랜드 정보 세팅
                    const findBrandMusic = this.buildingSoundList.listResult.find(
                      item => item.brandMusicId === musicId
                    );
                    if (findBrandMusic) {
                      spaceInfo.isBrandMusic = true;
                      const { brandMusicNm } = findBrandMusic;
                      spaceInfo.brandMusicName = brandMusicNm;
                    }

                    const { data } = await this.updatePlayingList(spaceId, musicList);
                    const { resultCd } = data;
                    if (resultCd === '0000') {
                      this.spaceList[spaceIndex].isPlayingMusicList = musicList;
                      const addUrlMusicList = this.settingMusicList(musicList, useIpSpeaker, musicId);
                      this.$store.commit('setMusicList', addUrlMusicList);
                      this.playMusic(spaceInfo, spaceIndex, 'NEW');
                    }
                    // fadeIn
                    this.$VideoPlayer.setMuted(false);
                    await this.$VideoPlayer.fadeInSound();
                  }
                }
              }
            }
          }
        });
      }
    },
    setBuildingSound () {
      this.deleteBuildingSoundJob();

      this.buildingSoundInfo = [];
      const list = this.buildingSoundList.listResult;
      for (const i in list) {
        if (list[i].playAt === 'Y') {
          if (list[i].playType === 'S') {
            // spaceIdList만큼 buildingSoundInfo에 추가
            const { spaceIdList } = list[i];
            if (Array.isArray(spaceIdList)) {
              const spaceIdListLen = spaceIdList.length;
              for (let spaceIdListIndex = 0; spaceIdListIndex < spaceIdListLen; spaceIdListIndex++) {
                const buildingSound = {
                  spaceId: spaceIdList[spaceIdListIndex].spaceId,
                  musicId: spaceIdList[spaceIdListIndex].brandMusicId,
                  name: 'brand' + list[i].brandSpaceMusicId,
                  minutes: list[i].selectTime.substring(3, 5),
                  hours: list[i].selectTime.substring(0, 2)
                };
                this.buildingSoundInfo.push(buildingSound);
              }
            }
          } else {
            const repeat = parseInt(list[i].repeatTime);

            const hours = [];
            const start = parseInt(list[i].startTime.split(':')[0]);
            const startMin = parseInt(list[i].startTime.split(':')[1]);
            const startTimeCalc = parseInt(start) * 60 + parseInt(startMin);

            const endHour = parseInt(list[i].endTime.split(':')[0]);
            const endMin = parseInt(list[i].endTime.split(':')[1]);
            const endTimeCalc = parseInt(endHour) * 60 + parseInt(endMin);
            const END_TIME = 60 * 23 + 55;
            const MAX_TIME = startTimeCalc >= endTimeCalc ? END_TIME : endTimeCalc;

            let minIndex = start;
            for (minIndex = start; parseInt(minIndex) * 60 + startMin <= MAX_TIME; minIndex += repeat) {
              hours.push(minIndex > 9 ? minIndex : `0${minIndex}`);
            }

            minIndex = minIndex > 23 ? minIndex - 24 : minIndex;
            if (startTimeCalc >= endTimeCalc) {
              for (let index = i; parseInt(index) * 60 + startMin <= endTimeCalc; index += repeat) {
                hours.push(index > 9 ? index : `0${index}`);
              }
            }

            const { spaceIdList } = list[i];
            if (Array.isArray(spaceIdList)) {
              const spaceIdListLen = spaceIdList.length;
              for (let spaceIdListIndex = 0; spaceIdListIndex < spaceIdListLen; spaceIdListIndex++) {
                const buildingSound = {
                  spaceId: spaceIdList[spaceIdListIndex].spaceId,
                  musicId: spaceIdList[spaceIdListIndex].brandMusicId,
                  name: 'brand' + list[i].brandSpaceMusicId,
                  minutes: list[i].startTime.substring(3, 5),
                  hours: hours.join(',')
                };
                this.buildingSoundInfo.push(buildingSound);
              }
            }
          }
        }
      }
      setTimeout(() => {
        this.setBrandMusic();
      }, 2000);
    },
    async reloadMusic () {
      if (this.$store.state.updateCurationForBuilding.isChange) {
        var spaceId = this.$store.state.updateCurationForBuilding.spaceId;
        var index = this.spaceList.findIndex(el => el.spaceId === spaceId);

        if (index !== -1) {
          const { socket, spaceId } = this.spaceList[index];
          this.$store.state.updateCurationForBuilding.isChange = false;
          if (socket === 'Y') {
            this.spaceList[index].isLoading = true;
            try {
              const { data: bldStopResponse } = await setBldgStop(spaceId, this.userId);
              const { resultCd, result } = bldStopResponse;
              if (resultCd === '0000') {
                const { action } = result;
                if (action === 'ok') {
                  // stop
                  this.spaceList[index].isPlayYn = 'N';
                  // start
                  const { data: bldPlayResponse } = await setBldgPlay(spaceId, this.userId);
                  const { resultCd, result } = bldPlayResponse;
                  if (resultCd === '0000') {
                    const { action } = result;
                    if (action === 'ok') {
                      this.spaceList[index].isPlayYn = 'Y';
                    } else {
                      throw new Error('socket start error');
                    }
                  } else {
                    throw new Error('setBldgPlay error');
                  }
                } else {
                  throw new Error('socket stop error');
                }
              } else {
                throw new Error('reloadMusic error');
              }
            } catch (error) {
              console.error('reloadMusic error : ', error);
            } finally {
              this.spaceList[index].isLoading = false;
            }
          } else {
            this.stopMusic(this.spaceList[index], index);
            setTimeout(() => {
              this.getMusicList(this.spaceList[index], index);
            }, 500);
          }
        }
      }
    },
    setAutoPlay (spaceId) {
      var index = this.autoPlayInfoList.findIndex(el => el.spaceId === spaceId);
      this.$crontab.addJob({
        name: 'counter',
        interval: {
          seconds: '/1'
        },
        job: this.countUp
      });

      var useAt = this.autoPlayInfoList[index].autoPlayInfo.useAt;
      if (useAt === 'Y') {
        // 자동재생 기능 사용 시 job등록
        // this.autoPlayInfoList[index].autoPlayInfo.week.
        const { week, startHours, startMinutes, endHours, endMinutes } = this.autoPlayInfoList[index].autoPlayInfo;
        week.forEach(item => {
          this.$AutoPlay.addJob([
            {
              id: 'startAutoPlay' + this.autoPlayInfoList[index].spaceId + '_' + item,
              day: parseInt(item),
              hours: parseInt(startHours),
              min: parseInt(startMinutes),
              seconds: 0,
              job: async () => {
                const find = this.spaceList.find(
                  spaceItem => spaceItem.spaceId === this.autoPlayInfoList[index].spaceId
                );
                const spaceIndex = this.spaceList.findIndex(
                  spaceItem => spaceItem.spaceId === this.autoPlayInfoList[index].spaceId
                );
                const { socket } = find;
                if (socket !== 'Y') {
                  console.log('trigger auto play');
                  const isPlay = find.isPlayYn === 'Y';

                  if (!isPlay) {
                    const space = cloneDeep(find);
                    space.spaceIndex = spaceIndex;
                    this.onPlayMusic(space);
                  } else {
                    console.log('not auto play start');
                  }
                }
              }
            }
          ]);
          this.$AutoPlay.addJob([
            {
              id: 'endAutoPlay' + this.autoPlayInfoList[index].spaceId + '_' + item,
              day: parseInt(item),
              hours: parseInt(endHours),
              min: parseInt(endMinutes),
              seconds: 0,
              job: async () => {
                const find = this.spaceList.find(
                  spaceItem => spaceItem.spaceId === this.autoPlayInfoList[index].spaceId
                );
                const spaceIndex = this.spaceList.findIndex(
                  spaceItem => spaceItem.spaceId === this.autoPlayInfoList[index].spaceId
                );
                const { socket } = find;
                if (socket !== 'Y') {
                  console.log('trigger auto stop');
                  const isPlay = find.isPlayYn === 'Y';
                  if (isPlay) {
                    const space = cloneDeep(find);
                    space.spaceIndex = spaceIndex;
                    this.onPauseMusic(space);
                  } else {
                    console.log('not auto play stop');
                  }
                }
              }
            }
          ]);
          // 현대캐피탈 하드코딩 자동재생/중지(08:45)
          this.setHyundaiCapitalAutoPlay(this.autoPlayInfoList[index].spaceId, item);
        });

        // 42dot_라운지/화장실(3F)자동재생/중지(매주 수요일 11:00 중지 , 11:30 재생)
        this.set42dotAutoPlay(this.autoPlayInfoList[index].spaceId, '3');
      }
    },
    countUp () {
      this.counter += 1;
      this.$store.commit('setCounter', this.counter);
    },
    deleteAutoPlayJob () {
      // 등록된 자동재생 cronjon 삭제
      const array = Object.keys(this.$crontab.jobs);

      this.array = array.filter(job => {
        return job.includes('space');
      });

      for (var j = 0; j < this.array.length; j++) {
        this.$crontab.deleteJob(this.array[j]);
      }
    },
    setPause (spaceId) {
      // 자동재생 멈춤
      const index = this.spaceList.findIndex(el => el.spaceId === spaceId);
      const spaceNm = this.spaceList[index].spaceNm;
      console.log('pause!');
      this.stopMusic(this.spaceList[index], index);

      const today = new Date();
      console.log(spaceNm + ' ' + '자동재생 스탑 : ' + today);
    },
    getAutoPlayInfo (spaceId) {
      // 자동재생 정보 셋팅
      const aToken = this.$cookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };

      axios
        .post('/api/profile/getAutoPlay', { spaceId }, { headers })
        .then(res => {
          // console.log(res.data);
          const result = res.data.result;
          var autoPlayInfo = {};
          // autoplay 요일지정
          autoPlayInfo.week = [];
          if (result.sun === 'Y') {
            autoPlayInfo.week.push(0);
          }
          if (result.mon === 'Y') {
            autoPlayInfo.week.push(1);
          }
          if (result.tue === 'Y') {
            autoPlayInfo.week.push(2);
          }
          if (result.wed === 'Y') {
            autoPlayInfo.week.push(3);
          }
          if (result.thu === 'Y') {
            autoPlayInfo.week.push(4);
          }
          if (result.fri === 'Y') {
            autoPlayInfo.week.push(5);
          }
          if (result.sat === 'Y') {
            autoPlayInfo.week.push(6);
          }
          // autoplay 시작시간
          autoPlayInfo.startHours = result.startTime.substring(0, 2);
          autoPlayInfo.startMinutes = result.startTime.substring(3, 5);
          // autoplay 종료시간
          autoPlayInfo.endHours = result.endTime.substring(0, 2);
          autoPlayInfo.endMinutes = result.endTime.substring(3, 5);
          // autoplay 사용여부
          autoPlayInfo.useAt = result.useAt;

          var index = this.autoPlayInfoList.findIndex(el => el.spaceId === spaceId);
          // index가 -1 이면 스페이스에 새로운 autoPlayInfo 추가, -1이 아니면 기존 스페이스에 autoPlayInfo 업데이트
          index === -1
            ? this.autoPlayInfoList.push({ autoPlayInfo: autoPlayInfo, spaceId: spaceId })
            : this.autoPlayInfoList.splice(index, 1, { autoPlayInfo: autoPlayInfo, spaceId: spaceId });
        })
        .then(() => {
          console.log('add setAutoPlay');
          this.setAutoPlay(spaceId);
        })
        .catch(err => {
          console.log(err);
        });
    },
    async getMusicList (space, i, brandMusicId, isReset) {
      // 재생버튼 클릭 시 뮤직리스트 가져오기
      const spaceId = space.spaceId;
      const aToken = this.$cookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };
      const timezone = '0';
      const email = this.$cookies.get('email');
      const date = '2022-03-15';
      axios
        .post('/api/player/getMusicList', { spaceId, timezone, email, date }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            const data = res.data.result;
            const musicList = data.map(item => ({
              musicId: item.musicId
            }));
            const index = this.spacePlayList.findIndex(el => el.spaceId === spaceId);
            // index가 -1 이면 스페이스에 새로운 musicList 추가, -1이 아니면 기존 스페이스에 musicList 업데이트
            index === -1
              ? this.spacePlayList.push({ playList: musicList, spaceId: spaceId })
              : this.spacePlayList.splice(index, 1, { playList: musicList, spaceId: spaceId });
          }
        })
        .then(() => {
          this.setPlayList(space, i, brandMusicId, isReset);
        })
        .catch(err => {
          console.log(err);
        });
    },
    modeCheck () {
      // 운영, 개발 체크
      const url = window.location.host;
      url.indexOf('localhost') !== -1 || url.indexOf('dev') !== -1 ? (this.mode = 'dev') : (this.mode = 'prod');
    },
    updateUnmute (spaceId, isMuteYn) {
      // DB에 음소거 해제 상태값 업데이트
      const aToken = this.$cookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };

      axios.post('/api/profile/updateSpaceMuteStatus', { spaceId, isMuteYn }, { headers }).catch(err => {
        console.log(err);
      });
    },
    async onUnMuteSound (space) {
      const { prePlayVolume, spaceId, spaceIp, socket, i, useIpSpeaker } = space;
      this.spaceList[i].isMuteYn = 'N';
      this.spaceList[i].playVolume = prePlayVolume;
      if (useIpSpeaker === 'Y') {
        if (socket === 'Y') {
          const params = {
            spaceId,
            playVolume: prePlayVolume,
            prePlayVolume,
            isMuteYn: 'N',
            userId: this.userId
          };
          try {
            const { data } = await setBldgVolume(params);
            const { resultCd } = data;
            if (resultCd !== '0000') {
              throw new Error('setBldgVolume error');
            }
          } catch (error) {
            this.spaceList[i].isMuteYn = 'Y';
            console.error('setBldgVolume error : ', error);
          } finally {
            this.$forceUpdate();
          }
        } else {
          const instance = axios.create();
          const target = spaceIp;
          const value = parseInt(prePlayVolume);
          const headers = {
            'Content-Type': 'text/plain;charset=UTF-8',
            Accept: '*/*'
          };

          instance
            .post('http://localhost:10099/api/setVolume', { target, value }, { headers })
            .then(res => {
              if (res.data.success === true) {
                this.updateMuteStatus(space, 'N');
              }
            })
            .catch(err => {
              console.log(err);
            });
        }
      } else {
        this.updateMuteStatus(space, 'N');
        this.$VideoPlayer.setMuted(false);
        this.$store.commit('setMute', false);
        this.$VideoPlayer.setVolume(this.$store.state.preVolume);
        const volume = this.$VideoPlayer.video.volume;
        this.$store.commit('setVolume', volume);
      }
    },
    async onMuteSound (space) {
      const { prePlayVolume, spaceId, socket, playVolume, i, useIpSpeaker, spaceIp } = space;
      // 재생중인 음원 음소거

      this.spaceList[i].prePlayVolume = playVolume;
      this.spaceList[i].isMuteYn = 'Y';
      this.spaceList[i].playVolume = '0';
      if (useIpSpeaker === 'Y') {
        if (socket === 'Y') {
          const params = {
            spaceId,
            playVolume: '0',
            prePlayVolume,
            isMuteYn: 'Y',
            userId: this.userId
          };
          try {
            const { data } = await setBldgVolume(params);
            const { resultCd } = data;
            if (resultCd !== '0000') {
              throw new Error('setBldgVolume error');
            }
          } catch (error) {
            console.error('setBldgVolume error : ', error);
            this.spaceList[i].isMuteYn = 'N';
          } finally {
            this.$forceUpdate();
          }
        } else {
          const instance = axios.create();
          const target = spaceIp;
          const value = 0;
          const headers = {
            'Content-Type': 'text/plain;charset=UTF-8',
            Accept: '*/*'
          };

          instance
            .post('http://localhost:10099/api/setVolume', { target, value }, { headers })
            .then(res => {
              if (res.data.success === true) {
                this.updateMuteStatus(space, 'Y');
              }
            })
            .catch(err => {
              console.log(err);
            });
        }
      } else {
        this.updateMuteStatus(space, 'Y');
        const curVolume = this.$VideoPlayer.video.volume;
        this.$store.commit('setPreVolume', curVolume);
        this.$VideoPlayer.setVolume(0);
        this.$store.commit('setVolume', 0);
        this.$store.commit('setMute', true);
      }
    },
    updateMuteStatus (space, isMuteYn) {
      // DB에 음소거 상태값 업데이트
      const spaceId = space.spaceId;
      if (isMuteYn === 'Y') var prePlayVolume = space.prePlayVolume;
      var playVolume = '0';
      isMuteYn === 'Y' ? (playVolume = '0') : (playVolume = space.prePlayVolume);
      const aToken = this.$cookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };

      axios
        .post('/api/profile/updateSpaceMuteStatus', { spaceId, isMuteYn, prePlayVolume, playVolume }, { headers })
        .catch(err => {
          console.log(err);
        });
    },
    playMediaPlaySetting () {
      this.$VideoPlayer.setMuted(false);
      this.$VideoPlayer.setAutoPlay(true);
      this.$VideoPlayer.setPlaysinline(true);
      this.$VideoPlayer.setVolume(this.$store.state.volume);
    },
    setMediaMusicUrl (url) {
      const tg =
        window.location.host.indexOf('localhost') !== -1 || window.location.host.indexOf('192.') !== -1
          ? 'http://13.209.152.125:10084' + url
          : url;
      this.$VideoPlayer.playerMusic(tg, this.config);

      this.playMediaAddErrorHandle();
    },
    playMediaAddErrorHandle () {
      this.$VideoPlayer.registerHlsErrorHandle(this.hlsErrorHandler);
    },
    retryMusic () {
      // 음악 재조회
      const findIdx = this.spaceList.findIndex(item => item.useIpSpeaker === 'N');
      if (findIdx > -1) {
        this.$store.commit('setPlay', true);
        const space = cloneDeep(this.spaceList[findIdx]);
        space.spaceIndex = findIdx;
        this.onPlayMusic(space);
      }
    },
    hlsErrorHandler (_, data) {
      console.log('Hls Error', data);
      if (data.fatal) {
        // fatal error case
        switch (data.type) {
          case Hls.ErrorTypes.NETWORK_ERROR:
            // try to recover network error
            console.log('fatal network error encountered, try to recover');
            this.$VideoPlayer.startLoad(-1);
            this.retryMusic();
            break;
          case Hls.ErrorTypes.MEDIA_ERROR:
            console.log('fatal media error encountered, try to recover');
            this.$VideoPlayer.recoverMediaError();
            break;
          default:
            this.$VideoPlayer.onPause();
            break;
        }
      } else {
        // non-fatal error case
        switch (data.details) {
          case Hls.ErrorDetails.LEVEL_EMPTY_ERROR:
            this.$VideoPlayer.onPause();
            break;
        }
      }
    },
    manageVideoEvnetListener () {
      console.log("call manageVideoEvnetListener");
      if (window.getEventListeners) {
        const { play, loadedmetadata, ended, pause, error, canplaythrough } = window.getEventListeners(document.querySelector('#video'));
        if (!play) {
          this.$VideoPlayer.registerVideoEvent('play', this.videoPlayEvent);
        }

        if (!loadedmetadata) {
          this.$VideoPlayer.registerVideoEvent('loadedmetadata', this.videoLoadMetaEvent);
        }

        if (!ended) {
          this.$VideoPlayer.registerVideoEvent('ended', this.videEndEvent);
        }

        if (!pause) {
          this.$VideoPlayer.registerVideoEvent('pause', this.videPauseEvent);
        }

        if (!error) {
          this.$VideoPlayer.registerVideoEvent('error', this.videErrorEvent);
        }

        if (!canplaythrough) {
          this.$VideoPlayer.registerVideoEvent('canplaythrough', this.videCanPlayThroughEvent);
        }
      } else {
        this.$VideoPlayer.registerVideoEvent('play', this.videoPlayEvent);
        this.$VideoPlayer.registerVideoEvent('loadedmetadata', this.videoLoadMetaEvent);
        this.$VideoPlayer.registerVideoEvent('ended', this.videEndEvent);
        this.$VideoPlayer.registerVideoEvent('pause', this.videPauseEvent);
        this.$VideoPlayer.registerVideoEvent('error', this.videErrorEvent);
        this.$VideoPlayer.registerVideoEvent('canplaythrough', this.videCanPlayThroughEvent);
      }
    },
    videoLoadMetaEvent (evt) {
      this.$store.commit('setTotDuration', new Date(evt.target.duration * 1000).toISOString().substr(14, 5));
      this.insertMusicPlay();
    },
    videoPlayEvent () {
      if (this.$store.state.pauseClickValue) {
        this.$store.state.currentMusicIdx = 0;
        this.setMediaMusicUrl(this.$store.state.musicList[this.$store.state.currentMusicIdx].src);
        this.$VideoPlayer.startLoad(-1);
        this.$VideoPlayer
          .onPlay()
          .then(() => {
            this.$VideoPlayer.onMute(false);
            this.$store.commit('setPlay', true);
          })
          .catch(_ => {
            //
          });
      }
      this.$store.commit('setPauseClickValue', false);

      if (document.location.hostname === 'www.aplayz.co.kr') {
        this.$gtag.event('music_playing', {
          space_id: this.$store.state.spaceId,
          space_nm: this.$store.state.spaceNm,
          music_idx: this.$store.state.currentMusicIdx,
          music_nm: this.$store.state.musicList[this.$store.state.currentMusicIdx].musicNm
        });
      }
    },
    videEndEvent () {
      if (++this.$store.state.currentMusicIdx > this.$store.state.musicList.length - 1) {
        this.$store.state.currentMusicIdx = 0;
      }
      this.setMediaMusicUrl(this.$store.state.musicList[this.$store.state.currentMusicIdx].src);
      this.$VideoPlayer.startLoad(-1);
      this.$VideoPlayer.onPlay().then(() => {
        this.$VideoPlayer.onMute(false);
      });
    },
    videPauseEvent () {
      console.log('get Pause');
      navigator.mediaSession.playbackState = 'paused';
      // check media ended
      // source buffers 내에 미디어 소스는 종료로 확인 상태에서 pause 상태로 멈춰 다음 곡이 재생 되지 않는 문제 해결
      try {
        if (this.$store.state.isPlay && this.$store.hls !== undefined) {
          // 이용자의 pause 상태 변경 없이 pause 호출 시
          console.log(
            'not triggered',
            this.$store.state.isPlay,
            this.$store.hls.bufferController.sourceBuffer.audio.ended
          );
          if (this.$store.hls.bufferController.sourceBuffer.audio.ended) {
            // 미디어 소스 버퍼의 로딩이 끝까지 완료 된 상태라면.. 1초뒤에도 pause 상태인지 확인 후 처리
            const mIdx = this.$store.state.currentMusicIdx;

            setTimeout(() => {
              this.checkFn(mIdx);
            }, 1000);
            // Stream Controller에서 다음 Fragment를 로딩하지 않고 멈춰 있는 경우가 있다.
            // 현재 Fragment 일련번호와 전체 일련번호가 같지 않다면 복구를 시도한다.
          } else if (this.$store.hls.streamController.fragCurrent.sn < this.$store.hls.bufferController.details.endSN) {
            console.log('Try to load next chunk file and replay.');
            this.$store.hls.startLoad();
            this.$VideoPlayer.onPlay();
          }
        }
      } catch (e) {
        console.log('Error', e);
      }
    },
    async videErrorEvent (error) {
      console.log('video error : ', error);
      const email = this.$cookies.get('email');
      this.errorlog.push(error);
      const findIdx = this.spaceList.findIndex(item => item.useIpSpeaker === 'N');
      if (findIdx > -1) {
        const space = this.spaceList[findIdx];
        await this.updateSpaceIsPlayYn(space.spaceId, 'N');
        this.spaceList[findIdx].isPlayYn = 'N';
        this.$VideoPlayer.onPause();
        this.$store.commit('setPlay', false);

        // play
        const musicList = await this.setSpaceMusicList(space.spaceId, '0', email, '2022-03-15');
        this.setUpdateSpaceList(space.spaceId, musicList);
        const { data } = await this.updatePlayingList(space.spaceId, musicList);
        const { resultCd } = data;
        if (resultCd === '0000') {
          this.spaceList[findIdx].isPlayingMusicList = musicList;
          const addUrlMusicList = this.settingMusicList(musicList, 'N');
          this.$store.commit('setMusicList', addUrlMusicList);
          this.playMusic(space, findIdx, 'NEW');
        }
      }
    },
    videCanPlayThroughEvent () {
      const promise = this.$VideoPlayer.onPlay();
      if (promise !== undefined) {
        promise
          .catch(err => {
            console.error(
              'Ready Status: ',
              this.$VideoPlayer.getVideoEle().readyState,
              'Auto-play was prevented: ',
              err,
              err.message,
              typeof err
            );
            window.videoErr = err;
            // if (err.toLowerCase().indexOf("because the user denied permission") !== -1) {
            if (this.$VideoPlayer.getVideoEle().readyState === 4) {
              // 자동재생 방지로 인한 메시지 또는 수동 재생버튼 클릭 메시지 필요
              this.$store.commit('setPlay', false);
              const findIdx = this.spaceList.findIndex(item => item.useIpSpeaker === 'N');
              this.spaceList[findIdx].isPlayYn = 'N';
              alert('재생버튼을 클릭해주세요.');
            }
          })
          .then(() => {
            if (Hls.isSupported()) {
              this.$VideoPlayer.setMuted(false);
              this.$VideoPlayer.setVolume(this.$store.state.volume);
            }
          });
      }
    },
    checkFn (idx) {
      console.log('paused trigger', 'before: ', idx, ', now: ', this.$store.state.currentMusicIdx);
      if (idx === this.$store.state.currentMusicIdx) {
        const video = this.$VideoPlayer.getVideoEle();
        video.dispatchEvent(new Event('ended'));
      }
    },
    playMediaMusic () {
      this.playMediaPlaySetting();
      this.setMediaMusicUrl(this.$store.state.musicList[this.$store.state.currentMusicIdx].src);
      this.manageVideoEvnetListener();
      this.$VideoPlayer.startLoad(-1);
      this.$VideoPlayer.onPlay().then(() => {
        this.$VideoPlayer.onMute(false);
      });
    },
    insertMusicPlay () {
      const historyMusicId = this.$store.state.historyMusicId;
      const musicId = this.$store.state.musicList[this.$store.state.currentMusicIdx].src.substring(17);
      const playTime = this.$store.state.totDuration;

      if (historyMusicId !== musicId) {
        const aToken = this.$cookies.get('aToken');
        const headers = { 'X-AUTH-TOKEN': aToken };
        axios
          .post('/api/player/insertMusicPlayHistory', { musicId, playTime }, { headers })
          .then(res => {
            this.$store.commit('setHistoryMusicId', musicId);
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    setMusicInfoList () {
      for (var i in this.spaceList) {
        if (this.spaceList[i].spacePayAt === 'Y') {
          this.getPlayMusicIdx(this.spaceList[i], i);
        }
      }
    },
    async setAutoPlayList () {
      this.deleteAutoPlayJob();
      for (var i in this.spaceList) {
        if (this.spaceList[i].spacePayAt === 'Y') {
          await this.getAutoPlayInfo(this.spaceList[i].spaceId);
        }
      }
    },
    async getPlayMusicIdx (space, i) {
      // status값이 멈춤일때 재생에서 정지로 바꾸는 로직 필요
      if (space.useIpSpeaker === 'Y') {
        const target = space.spaceIp;
        const { spaceId, socket } = space;
        if (target === null || target === undefined) {
          // spaceIp가 없고 전시장 계정이 아닌 경우
          this.spaceList[i].err = 'checkProgramStatus';
        } else {
          try {
            if (socket === 'Y') {
              const { data } = await getBldgState(spaceId);
              const { resultCd, result } = data;
              if (resultCd === '0000') {
                const { action, param } = result;
                if (action === 'ok') {
                  const { result: paramResult } = param;
                  this.spaceList[i].err = '';
                  if (paramResult[spaceId]) {
                    const { is_playing: isPlaying, volume } = paramResult[spaceId];
                    this.spaceList[i].playVolume = volume;
                    if (isPlaying === '1') {
                      this.spaceList[i].isPlayYn = 'Y';
                      this.getPlayingMusicInfo(null, i, spaceId);
                    } else {
                      this.spaceList[i].isPlayYn = 'N';
                    }
                  } else {
                    this.spaceList[i].isPlayYn = 'N';
                  }
                } else {
                  throw new Error('networkerror');
                }
              } else {
                throw new Error('networkerror');
              }
            } else {
              const res = await this.instance.post(
                'http://localhost:10099/api/getStatus',
                { target },
                { headers: this.headers }
              );
              const { success, data } = res.data;
              this.spaceList[i].err = '';
              if (success) {
                const idx = JSON.parse(data);
                if (idx.is_playing === '0') {
                  // is_playing값이 0(재생 중지)일때
                  if (space.isPlayYn === 'Y') await this.updateIsPlayYn(space.spaceId, 'N', i); // 재생 상태값 Y에서 N로 변경
                } else if (idx.is_playing === '1') {
                  // is_playing값이 1(재생 중)일때 재생 상태값이 N으로 되어 있는 경우 Y로 업데이트
                  if (space.isPlayYn === 'N') await this.updateIsPlayYn(space.spaceId, 'Y', i);
                  if (space.isPlayingMusicList) {
                    if (idx.play_idx !== false) {
                      this.getPlayingMusicInfo(space.isPlayingMusicList[idx.play_idx], i, spaceId);
                    }
                  }
                }
              }
            }
          } catch (error) {
            console.error('getStatus error : ', error);
            if (error.response === undefined) {
              if (error.message === 'networkerror') {
                this.spaceList[i].err = 'networkErr';
              } else {
                this.spaceList[i].err = 'checkProgramStatus';
              }
            } else {
              this.spaceList[i].err = 'networkErr';
            }
          }
        }
      } else {
        const { spaceId } = space;
        this.getPlayingMusicInfo('', i, spaceId);
      }
    },
    getPlayingMusicInfo (_, i, spaceId) {
      // 재생중인 음원 정보 가져오기
      const aToken = this.$cookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };
      axios
        .post('/api/player/getBuildingPlayMusicInfo', { spaceId }, { headers }) // 백엔드쪽에 홍보/방송 음원일 경우 음원정보 tb_brand_music 테이블에서 가져오도록 로직 추가
        .then(async res => {
          if (res.data.resultCd === '0000') {
            const result = res.data.result;
            if (result != null) {
              this.spaceList[i].artist = result.artist;
              this.spaceList[i].musicNm = result.musicNm;
              this.spaceList[i].albumImg = result.albumImg ? result.albumImg : '';
              this.spaceList[i].err = '';
              if ("mediaSession" in navigator) {
                const findIdx = this.spaceList.findIndex(item => item.useIpSpeaker === 'N');
                if (findIdx > -1) {
                  const musicInfo = this.spaceList[findIdx];

                  const { musicNm, artist, albumImg } = musicInfo;
                  // eslint-disable-next-line no-undef
                  navigator.mediaSession.metadata = new MediaMetadata({
                    title: musicNm,
                    artist,
                    artwork: [
                      {
                        src: `/media/album/aplayz.cover/${albumImg}`
                      }
                    ]
                  });
                }
              }
              await this.$forceUpdate();
              await this.$nextTick();
            }
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    async updateIsPlayingMusicList (spaceId, musicList) {
      const isPlayingMusicList = musicList.toString();
      const aToken = this.$cookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };
      await axios
        .post('/api/profile/updateSpaceIsPlayingMusicList', { spaceId, isPlayingMusicList }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            const index = this.spaceList.findIndex(el => el.spaceId === spaceId);
            this.spaceList[index].isPlayingMusicList = musicList;
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    updatePlayVolume (spaceId, playVolume) {
      const aToken = this.$cookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };

      axios.post('/api/profile/updateSpacePlayVolume', { spaceId, playVolume }, { headers }).catch(err => {
        console.log(err);
      });
    },
    async updateIsPlayYn (spaceId, isPlayYn, i) {
      const aToken = this.$cookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };
      axios
        .post('/api/profile/updateSpaceIsPlayYn', { spaceId, isPlayYn }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            this.spaceList[i].isPlayYn = isPlayYn;
            this.spaceList.push();
          }
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.spaceList[i].isLoading = false;
        });
    },
    async ipSpeakerSetVolume (target, space) {
      const instance = axios.create();
      const headers = {
        'Content-Type': 'text/plain;charset=UTF-8',
        Accept: '*/*'
      };
      const value = space.playVolume;

      instance.post('http://localhost:10099/api/setVolume', { target, value }, { headers }).catch(err => {
        console.log(err);
      });
    },
    ipSpeakerFadeInVolume (target, headers, standardVolume, volume) {
      console.log('call ipSpeakerFadeInVolume');
      return new Promise((resolve, reject) => {
        const instance = axios.create();
        const fadeIn = async volume => {
          if (standardVolume > volume) {
            await instance
              .post('http://localhost:10099/api/setVolume', { target, value: volume }, { headers })
              .then(() => {
                setTimeout(() => {
                  fadeIn(volume + 10);
                }, 1000);
              })
              .catch(err => {
                console.error('error : ', err);
                setTimeout(() => {
                  fadeIn(volume + 10);
                }, 1000);
              })
              .finally(() => {
                console.log('setvolume value : ', volume + 10);
              });
          } else {
            resolve('success');
          }
        };

        fadeIn(volume);
      });
    },
    ipSpeakerFadeOutVolume (target, headers, volume = 60) {
      console.log('call ipSpeakerFadeOutVolume');
      return new Promise((resolve, reject) => {
        const instance = axios.create();
        const fadeOut = async volume => {
          if (volume >= 10) {
            await instance
              .post('http://localhost:10099/api/setVolume', { target, value: volume }, { headers })
              .then(() => {
                setTimeout(() => {
                  fadeOut(volume - 10);
                }, 1000);
              })
              .catch(err => {
                console.error('error : ', err);
                setTimeout(() => {
                  fadeOut(volume - 10);
                }, 1000);
              });
          } else {
            resolve('success');
          }
        };

        fadeOut(volume);
      });
    },
    ipSpeakerStop (space, i) {
      console.log('call ipSpeakerStop!');
      const instance = axios.create();
      const target = space.spaceIp;
      const headers = {
        'Content-Type': 'text/plain;charset=UTF-8',
        Accept: '*/*'
      };
      instance
        .post('http://localhost:10099/api/stopMusic', { target }, { headers })
        .then(res => {
          const today = new Date();
          console.log('ipSpeaker 자동재생 스탑 : ' + today);
          if (res.data.success) {
            this.updateIsPlayYn(space.spaceId, 'N', i);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    async onSettingVolume (space) {
      const { spaceId, i, value, useIpSpeaker, spaceIp, socket } = space;
      this.updateUnmute(spaceId, 'N');
      this.spaceList[i].isMuteYn = 'N';
      this.spaceList[i].playVolume = value;
      if (useIpSpeaker === 'Y') {
        if (socket === 'Y') {
          try {
            await setBldgVolume({
              playVolume: value,
              spaceId,
              userId: this.userId
            });
          } catch (error) {
            console.error('setBldgVolume error : ', error);
          }
        } else {
          //
          const instance = axios.create();
          const target = spaceIp;
          const headers = {
            'Content-Type': 'text/plain;charset=UTF-8',
            Accept: '*/*'
          };
          instance
            .post('http://localhost:10099/api/setVolume', { target, value }, { headers })
            .then(res => {
              if (res.data.success === true) {
                this.updatePlayVolume(spaceId, value);
              }
            })
            .catch(err => {
              console.log(err);
            });
        }
      } else {
        this.updatePlayVolume(spaceId, value);
        this.$VideoPlayer.setVolume(value / 100);
        const volume = this.$VideoPlayer.video.volume;
        this.$store.commit('setVolume', volume);
        this.$VideoPlayer.setMuted(false);
        this.$store.commit('setMute', false);
      }
    },
    async setVolume (space, e, i) {
      this.updateUnmute(space.spaceId, 'N');
      const value = e.currentTarget.value;
      this.spaceList[i].isMuteYn = 'N';
      this.spaceList[i].playVolume = value;
      if (space.useIpSpeaker === 'Y') {
        const { spaceId, spaceIp, socket } = space;
        if (socket === 'Y') {
          try {
            await setBldgVolume({
              playVolume: value,
              spaceId,
              userId: this.userId
            });
          } catch (error) {
            console.error('setBldgVolume error : ', error);
          }
        } else {
          const instance = axios.create();
          const target = spaceIp;
          const headers = {
            'Content-Type': 'text/plain;charset=UTF-8',
            Accept: '*/*'
          };

          instance
            .post('http://localhost:10099/api/setVolume', { target, value }, { headers })
            .then(res => {
              if (res.data.success === true) {
                this.updatePlayVolume(space.spaceId, value);
              }
            })
            .catch(err => {
              console.log(err);
            });
        }
      } else {
        this.updatePlayVolume(space.spaceId, value);
        var video = document.getElementById('video');
        video.volume = e.currentTarget.value / 100;
        this.$store.commit('setVolume', video.volume);
        // 볼륨 조절 시 뮤트 해제
        video.muted = false;
        this.$store.commit('setMute', false);
      }
    },
    setUpdateSpaceList (spaceId, musicList) {
      const index = this.spacePlayList.findIndex(el => el.spaceId === spaceId);
      index === -1
        ? this.spacePlayList.push({ playList: musicList, spaceId: spaceId })
        : this.spacePlayList.splice(index, 1, { playList: musicList, spaceId: spaceId });
    },
    async updatePlayingList (spaceId, musicList) {
      const isPlayingMusicList = musicList.toString();
      return await axios.post('/api/profile/updateSpaceIsPlayingMusicList', { spaceId, isPlayingMusicList });
    },
    async ipSpeakerPlayMusic (target) {
      const instance = axios.create();
      const headers = {
        'Content-Type': 'text/plain;charset=UTF-8',
        Accept: '*/*'
      };
      return await instance.post('http://localhost:10099/api/playMusic', { target }, { headers });
    },
    async ipSpeakerPauseMusic (target, n = 0, successFn = null, failFn = null, finallyFn = null) {
      const instance = axios.create();
      const headers = {
        'Content-Type': 'text/plain;charset=UTF-8',
        Accept: '*/*'
      };
      return new Promise(() => {
        const timeout = n === 0 ? 0 : 1500;
        if (n < 15) {
          return setTimeout(async () => {
            return await instance
              .post('http://localhost:10099/api/stopMusic', { target }, { headers })
              .then(res => {
                if (res.data.success) {
                  if (successFn) {
                    successFn();
                  }
                } else {
                  this.ipSpeakerPauseMusic(target, n + 1, finallyFn);
                }
              })
              .catch(() => {
                this.ipSpeakerPauseMusic(target, n + 1, finallyFn);
              })
              .finally(() => {
                if (finallyFn) {
                  console.log('finally ');
                  finallyFn();
                }
              });
          }, timeout);
        } else {
          if (failFn) {
            failFn();
          }
        }
      });
    },
    async onPauseMusic (spaceInfo) {
      const { useIpSpeaker, spaceId, spaceIndex, socket, spaceIp } = spaceInfo;
      this.spaceList[spaceIndex].isLoading = true;
      if (useIpSpeaker === 'Y') {
        if (socket === 'Y') {
          try {
            const { data: bldStopResponse } = await setBldgStop(spaceId, this.userId);
            const { resultCd, result } = bldStopResponse;
            if (resultCd === '0000') {
              const { action } = result;
              if (action === 'ok') {
                this.spaceList[spaceIndex].isPlayYn = 'N';
              } else {
                throw new Error('socket stop error');
              }
            } else {
              throw new Error('setBldgStop error');
            }
          } catch (error) {
            console.error('setBldgStop error : ', error);
          } finally {
            this.spaceList[spaceIndex].isLoading = false;
          }
        } else {
          this.ipSpeakerPauseMusic(
            spaceIp,
            0,
            async () => {
              await this.updateSpaceIsPlayYn(spaceId, 'N');
              this.spaceList[spaceIndex].isPlayYn = 'N';
              this.spaceList[spaceIndex].isLoading = false;
            },
            async () => {
              await this.updateSpaceIsPlayYn(spaceId, 'N');
              this.spaceList[spaceIndex].isPlayYn = 'N';
              this.spaceList[spaceIndex].isLoading = false;
            }
          );
        }
      } else {
        this.$store.commit('setPauseClickValue', true);
        await this.updateSpaceIsPlayYn(spaceId, 'N');
        this.spaceList[spaceIndex].isPlayYn = 'N';
        this.$VideoPlayer.onPause();
        this.$store.commit('setPlay', false);
        this.spaceList[spaceIndex].isLoading = false;
      }
    },
    async onPlayMusic (spaceInfo) {
      const { useIpSpeaker, spaceId, socket, spaceIndex, spaceIp } = spaceInfo;
      this.spaceList[spaceIndex].isLoading = true;
      if (socket === 'Y') {
        try {
          const { data: bldPlayResponse } = await setBldgPlay(spaceId, this.userId);
          const { resultCd, result } = bldPlayResponse;
          if (resultCd === '0000') {
            const { action } = result;
            if (action === 'ok') {
              this.spaceList[spaceIndex].isPlayYn = 'Y';
            } else {
              throw new Error('socket start error');
            }
          } else {
            throw new Error('setBldgPlay error');
          }
        } catch (error) {
          console.error('onPlayMusic error : ', error);
        } finally {
          this.spaceList[spaceIndex].isLoading = false;
        }
      } else {
        try {
          const musicList = await this.setSpaceMusicList(spaceId, '0', this.email, '2022-03-15');
          this.setUpdateSpaceList(spaceId, musicList);
          if (useIpSpeaker === 'Y') {
            const addUrlMusicList = this.settingMusicList(musicList, useIpSpeaker);
            const isSuccess = await this.playIpSpeaker(0, spaceInfo, spaceIp, addUrlMusicList);
            if (isSuccess) {
              const { data } = await this.updatePlayingList(spaceId, musicList);
              const { resultCd } = data;
              if (resultCd === '0000') {
                this.spaceList[spaceIndex].isPlayingMusicList = musicList;
                const { data } = await this.ipSpeakerPlayMusic(spaceIp);
                const { success } = data;
                if (success) {
                  const { data: updateSpace } = await this.updateSpaceIsPlayYn(spaceId, 'Y');
                  const { resultCd: updateSpaceResultCd } = updateSpace;
                  if (updateSpaceResultCd === '0000') {
                    this.spaceList[spaceIndex].isPlayYn = 'Y';
                  }
                } else {
                  throw new Error('ipSpeakerPlayMusic error');
                }
              } else {
                throw new Error('updatePlayingList error');
              }
            } else {
              throw new Error('playIpSpeaker error');
            }
          } else {
            const { data } = await this.updatePlayingList(spaceId, musicList);
            const { resultCd } = data;
            if (resultCd === '0000') {
              this.spaceList[spaceIndex].isPlayingMusicList = musicList;
              const addUrlMusicList = this.settingMusicList(musicList, useIpSpeaker);
              this.$store.commit('setMusicList', addUrlMusicList);
              await this.updateIsPlayYn(spaceId, 'Y', spaceIndex);
              this.$store.commit('setSpaceId', spaceId);
              this.$store.commit('setCurrentMusicIdx', 0);
              this.$store.commit('setPlay', true);
              this.playMediaMusic();
            } else {
              throw new Error('error updatePlayingList');
            }
          }
        } catch (error) {
          console.error('onPlayMusic error : ', error);
        } finally {
          this.spaceList[spaceIndex].isLoading = false;
        }
      }
    },
    async updateSpaceIsPlayYn (spaceId, isPlayYn) {
      return await axios.post('/api/profile/updateSpaceIsPlayYn', { spaceId, isPlayYn });
    },
    async setSpaceMusicList (spaceId, timeZome = '0', email, date) {
      try {
        const { data } = await getMusicList(spaceId, timeZome, email, date);
        const { resultCd, result } = data;
        if (resultCd === '0000') {
          return result.map(item => item.musicId);
        } else {
          return [];
        }
      } catch (error) {
        return [];
      }
    },
    settingMusicList (musicList, useIpSpeaker, brandMusicId = null) {
      const devBaseUrl = 'https://dev.www.aplayz.co.kr/stream/getMusic?';
      const prodBaseUrl = 'https://www.aplayz.co.kr/stream/getMusic?';
      const baseUrl = this.mode === 'dev' ? devBaseUrl : prodBaseUrl;
      if (useIpSpeaker === 'Y') {
        const addBaseUrlMusicList = musicList.map(item => baseUrl + item);
        if (brandMusicId != null) {
          addBaseUrlMusicList.unshift(baseUrl + brandMusicId);
        }

        return addBaseUrlMusicList;
      } else {
        const addMusicList = musicList.map(item => ({
          src: '/stream/getMusic?' + item
        }));
        if (brandMusicId != null) {
          addMusicList.unshift({ src: '/stream/getMusic?' + brandMusicId });
        }

        return addMusicList;
      }
    },
    playIpSpeaker (n = 0, space, target, value) {
      const instance = axios.create();
      const headers = {
        'Content-Type': 'text/plain;charset=UTF-8',
        Accept: '*/*'
      };
      return new Promise((resolve, reject) => {
        const timeout = n === 0 ? 0 : 1500;
        if (n < 15) {
          //
          setTimeout(async () => {
            console.log(space.spaceNm + ' ' + 'playIpSpeaker 시도 횟수  : ', n);
            await instance
              .post('http://localhost:10099/api/setMusicList', { target, value }, { headers })
              .then(async res => {
                if (res.data.success) {
                  resolve('success');
                } else {
                  this.playIpSpeaker(n + 1, space, target, value);
                }
              })
              .catch(() => {
                this.playIpSpeaker(n + 1, space, target, value);
              });
          }, timeout);
        } else {
          reject(Error('fail playIpSpeaker'));
        }
      });
    },
    async getStatusBuilding (target) {
      const instance = axios.create();
      const headers = {
        'Content-Type': 'text/plain;charset=UTF-8',
        Accept: '*/*'
      };

      return await instance.post('http://localhost:10099/api/getStatus', { target }, { headers });
    },
    retryStopMusic (target, i, space, n) {
      console.log('retryStopMusic');
      const instance = axios.create();
      const headers = {
        'Content-Type': 'text/plain;charset=UTF-8',
        Accept: '*/*'
      };
      return new Promise((resolve, reject) => {
        if (n < 15) {
          setTimeout(async () => {
            console.log(space.spaceNm + ' ' + 'retryStopMusic 시도 횟수  : ', n);
            instance
              .post('http://localhost:10099/api/stopMusic', { target }, { headers })
              .then(async res => {
                if (res.data.success) {
                  const result = await this.getStatusBuilding(target);
                  const { data } = result.data;
                  const resdata = JSON.parse(data);
                  // eslint-disable-next-line camelcase
                  const { is_playing } = resdata;
                  this.updateIsPlayYn(space.spaceId, 'N', i);
                  resolve('success');
                  if (parseInt(is_playing) !== 0) {
                    console.log('RETRY STOP MUSIC');
                    this.retryStopMusic(target, i, space, 0);
                  }
                }
              })
              .catch(err => {
                console.log('stopMusic error : ', err);
                this.retryStopMusic(target, i, space, n + 1);
              })
              .finally(() => {
                this.spaceList[i].isLoading = false;
              });
          }, 1500);
        } else {
          console.log('api/stopMusic 총 시도 횟수  : ', n);
          reject(new Error('stopMusic Error'));
          this.getStatusBuilding(target).then(result => {
            const { data } = result.data;
            const resdata = JSON.parse(data);
            // eslint-disable-next-line camelcase
            const { is_playing } = resdata;
            if (parseInt(is_playing) !== 0) {
              console.log('RETRY STOP MUSIC ERROR');
              this.retryStopMusic(target, i, space, 0);
            }
          });
        }
      });
    },
    async stopMusic (space, i) {
      // 음원 재생 멈춤
      if (space.useIpSpeaker === 'Y' && space.err !== 'checkProgramStatus') {
        // ip스피커 사용하는 경우
        const target = space.spaceIp;
        await this.retryStopMusic(target, i, space, 1);
      } else {
        // ip스피커 미사용하는 경우
        this.$store.commit('setPauseClickValue', true);
        await this.updateIsPlayYn(space.spaceId, 'N', i);
        const video = document.getElementById('video');
        video.pause();
        this.$store.commit('setPlay', false);
      }
    },
    async playMusic (space, i, isReset = '') {
      // 음원 재생 시작
      if (space.useIpSpeaker === 'Y') {
        // ip스피커 사용하는 경우
        const instance = axios.create();
        const target = space.spaceIp;
        const headers = {
          'Content-Type': 'text/plain;charset=UTF-8',
          Accept: '*/*'
        };
        await instance
          .post('http://localhost:10099/api/playMusic', { target }, { headers })
          .then(res => {
            if (res.data.success === true) {
              this.updateIsPlayYn(space.spaceId, 'Y', i);
            }
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        // ip스피커 미사용하는 경우
        await this.updateIsPlayYn(space.spaceId, 'Y', i);
        const intervalId9 = setInterval(async () => {
          if (document.getElementById('video') !== null) {
            clearInterval(intervalId9);
            if (space.spaceId !== this.$store.state.spaceId || isReset === 'NEW') {
              this.$store.commit('setSpaceId', space.spaceId);
              this.$store.commit('setCurrentMusicIdx', 0);
              this.$store.commit('setPlay', true);
              this.playMediaMusic();
            } else {
              const video = document.getElementById('video');
              video.play();
              this.$store.commit('setPlay', true);
            }
          }
        }, 500);
      }
    },
    async retrySetPlayList (target, value, musicList, space, i, n) {
      const instance = axios.create();
      const headers = {
        'Content-Type': 'text/plain;charset=UTF-8',
        Accept: '*/*'
      };
      return new Promise((resolve, reject) => {
        if (n < 15) {
          setTimeout(async () => {
            console.log(space.spaceNm + ' ' + 'retrySetPlayList 시도 횟수  : ', n);
            await instance
              .post('http://localhost:10099/api/setMusicList', { target, value }, { headers })
              .then(async res => {
                if (res.data.success === true) {
                  await this.updateIsPlayingMusicList(space.spaceId, musicList);
                  await this.playMusic(space, i);
                  resolve('success');
                }
              })
              .catch(err => {
                this.retrySetPlayList(target, value, musicList, space, i, n + 1);
                console.log('setMusicList error : ', err);
              });
          }, 1500);
        } else {
          console.log('api/setMusicList 총 시도 횟수  : ', n);
          reject(new Error('setMusicList Error'));
        }
      });
    },
    async setPlayList (space, i, brandMusicId, isReset) {
      // 플레이 리스트 셋팅
      const devBaseUrl = 'https://dev.www.aplayz.co.kr/stream/getMusic?';
      const prodBaseUrl = 'https://www.aplayz.co.kr/stream/getMusic?';
      const baseUrl = this.mode === 'dev' ? devBaseUrl : prodBaseUrl;
      // temp
      // this.mode === 'dev' ? baseUrl = 'http://13.209.152.125:10084/stream/getMusic?' : baseUrl = 'https://www.aplayz.co.kr/stream/getMusic?';
      const playList = this.spacePlayList.filter(el => el.spaceId === space.spaceId);
      const musicList = playList[0].playList.map(el => el.musicId);
      // brandMusicId값이 있을 경우 홍보/방송 음원을 맨앞에 추가 - DB
      if (brandMusicId != null) {
        musicList.unshift(brandMusicId);
      }
      // ip스피커 사용하는 경우
      if (space.useIpSpeaker === 'Y') {
        const value = playList[0].playList.map(el => baseUrl + el.musicId);
        // brandMusicId값이 있을 경우 홍보/방송 음원을 맨앞에 추가 - ip스피커
        if (brandMusicId != null) {
          value.unshift(baseUrl + brandMusicId);
        }
        const target = space.spaceIp;
        if (space.err !== 'checkProgramStatus') {
          await this.retrySetPlayList(target, value, musicList, space, i, 1);
        }
      } else {
        // ip스피커 미사용하는 경우
        await this.updateIsPlayingMusicList(space.spaceId, musicList);
        const musicList2 = playList[0].playList.map(el => ({
          src: '/stream/getMusic?' + el.musicId
        }));
        // brandMusicId값이 있을 경우 홍보/방송 음원을 맨앞에 추가 - 브라우저
        if (brandMusicId != null) {
          musicList2.unshift({ src: '/stream/getMusic?' + brandMusicId });
        }
        this.$store.commit('setMusicList', musicList2);
        this.playMusic(space, i, isReset);
      }
    },
    onDisconnectBrand (brand) {
      this.brandId = brand.brandId;
      this.brandNm = brand.brandNm;
      this.spaceId = brand.spaceId;
      this.isDisconnect = true;
    },
    onUpdateFranchise (space) {
      localStorage.setItem('spaceId', space.spaceId);
      this.$router
        .push({ name: 'Franchise', params: { spaceNm: space.spaceNm, spaceType: space.spaceType } })
        .catch(err => {
          console.error('Franchise router error : ', err);
        });
    },
    onDeleteSpace (space) {
      this.spaceId = space.spaceId;
      this.spaceNm = space.spaceNm;
      this.ShowDelete = true;
    },
    linkInsertSpace () {
      // 스페이스 등록 이동
      router.push({ name: 'SpaceInsertBuilding', params: { spaceId: null } }).catch(err => {
        console.error('SpaceInsertBuilding router error : ', err);
      });
    },
    /**
     * @description 스페이스 정보조회
     */
    getList () {
      this.limit = 999;
      this.spaceList = [];
      this.setSpaceList();
    },
    async setSpaceList () {
      const userId = this.$cookies.get('userId');
      const spaceId = this.$cookies.get('spaceId');
      const limit = this.limit;

      try {
        const { data } = await getMySpaceList(userId, limit, spaceId);
        const { result } = data;
        const { spaceInfoListInfo } = result;
        const len = spaceInfoListInfo.length;
        for (let spaceInfListInfoIndex = 0; spaceInfListInfoIndex < len; spaceInfListInfoIndex++) {
          // 인디음악 제거
          if (spaceInfoListInfo[spaceInfListInfoIndex].genre) {
            spaceInfoListInfo[spaceInfListInfoIndex].genre = spaceInfoListInfo[spaceInfListInfoIndex].genre.filter(
              genre => genre !== '인디음악'
            );
          }
          // 브랜드 이미지 substr
          if (spaceInfoListInfo[spaceInfListInfoIndex].brandImg) {
            spaceInfoListInfo[spaceInfListInfoIndex].brandImg = spaceInfoListInfo[
              spaceInfListInfoIndex
            ].brandImg.substr(12);
          }
          spaceInfoListInfo[spaceInfListInfoIndex].isLoading = false;
          this.spaceList.push(spaceInfoListInfo[spaceInfListInfoIndex]);
        }
      } catch (error) {
        if (error.response) {
          this.spaceList = [];
        }
        console.error('setSpaceList error : ', error);
      } finally {
        this.setMusicInfoList();
        this.setAutoPlayList();
        setTimeout(() => {
          this.reloadMusic();
        }, 2000);
        if (this.$MediaSession.init()) {
          const findIdx = this.spaceList.findIndex(item => item.useIpSpeaker === 'N');
          if (findIdx > -1) {
            const space = this.spaceList[findIdx];
            space.spaceIndex = findIdx;
            this.$MediaSession.addPlay(() => {
              this.onPlayMusic(space);
            });

            this.$MediaSession.addPause(() => {
              this.onPauseMusic(space);
            });
          }
          this.$MediaSession.addPrevioustrack(null);
          this.$MediaSession.addNexttrack(null);
        }
      }
    },
    onMovePageNoParam ({ spaceId, routerName }) {
      localStorage.setItem('spaceId', spaceId);
      this.$router.push({ name: routerName }).catch(err => {
        console.error('AutoPlay router error : ', err);
      });
    }
  }
};
</script>
<style scoped src="@/assets/css/building/myspacebuilding.css"></style>
